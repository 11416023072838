<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="450"
            permanent
        >
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Detalle del registro
                </v-card-title>

                <v-card-text class="pt-2">
                    <b>Registrado por</b> {{dt.creado}} <b>el</b> {{dt.dateCreated}}<br/>
                    <b>Hora:</b> {{dt.hora}}<br/>
                    {{dt.observaciones}}
                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text
                        color="error"
                        @click="borra(dt.id)"
                    >
                        Borrar
                    </v-btn>
                    
                    <v-btn
                        color="success"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        dt:Object,
        dialogApp:Boolean,
    },

    methods:{

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraMedicamentoAplicado',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.$emit('agregado')
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borra(id){
            //console.log(id)

            this.dialog = false


            this.$swal.fire({
                title: 'Borrar este registro',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {
                        //console.log(id)
                        //console.log(value)

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })

        },
        cierra(){
            this.dialog = false
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogApp
            },
            set(val){
                //console.log(val)
                this.$emit('update:dialogApp',val)
            },


        },
    }
}
</script>

<style>

</style>