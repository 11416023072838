var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"boleaForm",model:{value:(_vm.validaMed),callback:function ($$v) {_vm.validaMed=$$v},expression:"validaMed"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2",attrs:{"primary-title":""}},[_vm._v(" Aplicación de medicamentos "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":_vm.cancela}},[_c('v-icon',[_vm._v("mdi-close-box")])],1)],1),_c('v-card-text',[_c('v-alert',{staticClass:"mt-2",attrs:{"dense":"","text":"","border":"left","color":"info"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.fechaBol)+" "),_c('b',[_vm._v(_vm._s(_vm.turno))])])]),_c('span',[_c('b',[_vm._v(_vm._s(_vm.entrada.concepto))]),_vm._v(" "+_vm._s(_vm.entrada.especificacion))]),_c('br'),_c('span',[_vm._v(" Dosis: "+_vm._s(_vm.entrada.dosis)+" "+_vm._s(_vm.entrada.unidad)+"."),_c('br'),_vm._v(" Frecuencia: "+_vm._s(_vm.entrada.frecuencia)),_c('br'),_vm._v(" Via: "+_vm._s(_vm.entrada.via)+" ")]),_c('v-form',{ref:"validaHora",model:{value:(_vm.validaHora),callback:function ($$v) {_vm.validaHora=$$v},expression:"validaHora"}},[_c('v-text-field',{ref:"signoHora",staticClass:"mt-3",attrs:{"rules":[
                            value => !!value || 'Requerido.',
                            value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                        ],"rounded":"","clearable":"","outlined":"","label":"Hora (HH:MM 24 hrs)","dense":"","hint":"Despues de media noche, usar día anterior","persistent-hint":""},model:{value:(_vm.hora),callback:function ($$v) {_vm.hora=$$v},expression:"hora"}}),_c('v-text-field',{attrs:{"rounded":"","clearable":"","outlined":"","label":"Observaciones","dense":""},model:{value:(_vm.observaciones),callback:function ($$v) {_vm.observaciones=$$v},expression:"observaciones"}}),_c('v-expand-transition',[(_vm.agregaBalance)?_c('v-text-field',{attrs:{"rounded":"","clearable":"","outlined":"","label":"Cantidad (ml)","dense":"","type":"number","rules":[
                                value => !!value || 'Requerido.',
                                value => value > 0 || 'Valor MENOR al mínimo (0)',
                            ]},model:{value:(_vm.ml),callback:function ($$v) {_vm.ml=_vm._n($$v)},expression:"ml"}}):_vm._e()],1)],1),_c('v-checkbox',{attrs:{"label":"Agregar a balance"},model:{value:(_vm.agregaBalance),callback:function ($$v) {_vm.agregaBalance=$$v},expression:"agregaBalance"}}),_c('v-spacer')],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"error","disabled":!_vm.validaHora,"loading":_vm.loadingMed},on:{"click":_vm.noAplica}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" No aplicado ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"success","disabled":!_vm.validaHora,"loading":_vm.loadingMed},on:{"click":_vm.aplica}},[_vm._v(" Aplicado "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }