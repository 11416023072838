<template>
    <v-sheet elevation="2" width="100%">

        <v-toolbar short dense color="primary" dark flat>

            <v-toolbar-title>Medicamentos</v-toolbar-title>

            <v-spacer></v-spacer>
            <span class="pr-2">Activos</span>
            <v-switch
                class="pt-5"
                color="green lighten-4"
                label="Aplicados"
                v-model="medActivo"
                value=1

            >
            </v-switch>
            <v-btn icon @click="cargaMedTodos" :disabled="muestraMed">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-btn  dark @click="dialogMed=true" :disabled="muestraMed" rounded color="blue">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-toolbar>
        <!-- ********************************************* 
            EL SIMPLE TABLE PARA LOS ACTIVOS
        *************************************************** -->
        <v-toolbar color="primary" dark dense flat v-if="!medActivo">
            <v-toolbar-title>Medicamentos activos</v-toolbar-title>

            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                height="5"
                color="white"
            />

        </v-toolbar>
        <v-simple-table v-if="!medActivo">
            <template v-slot:default>

                <thead>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Medicamento</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Frec.</span></th>
                        <th class="primary lighten-3 text-center" v-for="itm in horariosApp" :key="itm.id"><span class="body-2 font-weight-black">{{itm.label.split(':')[0]}}</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Prox</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="mb-3" v-for="item in datosTable" :key="item.id">

                        <!-- el ícono SRAM -->

                        <td width="1%" class="textoarriba tablaDiag text-center" >
                            <v-tooltip bottom color="warning">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="amber lighten-2"
                                        class="mt-3 ml-1"
                                        @click="abreNuevoSram(item)"
                                    >
                                        mdi-alert
                                    </v-icon>
                                </template>
                                <span>Reporte S.R.A.M.</span>
                            </v-tooltip>

                            <v-tooltip bottom color="red" v-if="item.mar == 1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red lighten-2"
                                        class="mt-3 ml-1"
                                    >
                                        mdi-square
                                    </v-icon>
                                </template>
                                <span>Medicamento de alto riesgo</span>
                            </v-tooltip>
                        </td>

                        <!-- Descripción de medicamento -->
                        <td class="textoarriba tablaDiag" @click="detalleMed(item)">
                            <!-- Activo -->
                            <div v-if="item.activo == 1">

                                <span class="red--text text-caption" v-if="item.antibiotico">
                                    <b>{{item.concepto}} {{item.especificacion}}</b><br/>
                                </span>

                                <span v-if="!item.antibiotico" class="text-caption">
                                    {{item.concepto}} {{item.especificacion}}<br/>
                                </span>

                                <span class="text-caption">{{item.dosis}} {{item.unidad}} {{item.via}}</span><br/>

                                <span>
                                    {{item.observaciones}}
                                </span>
                            </div>

                            <div v-if="item.activo == 0">
                                <!-- Cancelado -->
                                <span class="red--text text-caption" v-if="item.antibiotico"  STYLE="text-decoration: line-through">
                                    <b>{{item.concepto}} {{item.especificacion}}</b><br/>
                                </span>
                                <span v-if="!item.antibiotico"  class="text-caption" STYLE="text-decoration: line-through">
                                    {{item.concepto}} {{item.especificacion}}<br/>
                                </span>
                                <span class="text-caption">{{item.dosis}} {{item.unidad}} {{item.via}}</span><br/>
                                <span>
                                    {{item.observaciones}}
                                </span>
                                <span v-if="item.activo == 0" class="red--text font-weight-black"><br/>Suspendido</span>
                                <span v-if="item.activo == 0" class="text-caption"> {{item.fechaSuspende}} por {{item.suspende}}: {{item.razonSuspension}}</span>
                            </div>

                        </td>

                        <td width="1%" class="tablaDiag text-center" >
                            <div>
                                <span class="text-caption">{{item.frecuencia}}</span><br/>

                            </div>
                        </td>

                        <td width="1%" class="tablaDiag text-center" v-for="itm in horariosApp" :key="itm.id">
                            <v-icon small v-if="separaAplicaciones(item, itm.label.split(':')[0])" @click="procesaMed(item, itm.label)">mdi-circle-medium</v-icon>
                            <!-- <v-icon small color="blue" v-if="!separaAplicaciones(item, itm.label.split(':')[0])" @click="entregaDatosAplicacion(item.aplicados, itm.hora)">mdi-check-bold</v-icon> -->
                            <v-chip
                                :color="entregaDatosColor(item.aplicados, itm.hora)"
                                v-if="(!separaAplicaciones(item, itm.label.split(':')[0]) && !noAplicado(item.aplicados, itm.hora))"
                                @click="entregaDatosAplicacion(item.aplicados, itm.hora)"
                                x-small
                                outlined
                            >
                                {{entregaDatosAplicacion2(item.aplicados, itm.hora)}}
                            </v-chip>
                            <div
                                :style="{
                                    color:entregaDatosColor(item.aplicados, itm.hora),
                                    fontSize: '10px'}"

                                v-if="(!separaAplicaciones(item, itm.label.split(':')[0]) && noAplicado(item.aplicados, itm.hora))"
                                @click="entregaDatosAplicacion(item.aplicados, itm.hora)"
                            >
                                <span>{{entregaDatosAplicacion2(item.aplicados, itm.hora)}}<br/>¡No!</span>
                            </div>
                            <div class="text-caption" v-if="entregaDatosAplicacion3(item.aplicados, itm.hora)">{{entregaDatosAplicacion3(item.aplicados, itm.hora)}} ml</div>
                        </td>

                        <!-- Próxima aplicacion -->
                        <td width="1%" class="text-center" >
                            <div>
                                <span class="text-caption">{{proximaAplicacion(item)}}</span><br/>
                            </div>
                        </td>

                        <!-- el ícono cancelar -->
                        <td width="1%" class="textoarriba tablaDiag" >
                            <v-icon small v-if="item.activo == 1" class="mt-3" @click="cancelaMed(item.id)">mdi-eye-off</v-icon>
                        </td>

                    </tr>
                    <tr>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Medicamento</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Frec.</span></th>
                        <th class="primary lighten-3 text-center" v-for="itm in horariosApp" :key="itm.id"><span class="body-2 font-weight-black">{{itm.label.split(':')[0]}}</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black">Prox</span></th>
                        <th class="primary lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>


        <!-- *********************************************
            EL SIMPLE TABLE PARA LOS SUMINISTRADOS DEL DÍA
        *************************************************** -->
        <v-toolbar color="orange" dark dense flat v-if="medActivo" class="mt-3">
            <v-toolbar-title>Medicamentos aplicados el {{estaFecha}}</v-toolbar-title>

            <v-progress-linear
                v-if="loadingDatos"
                :active="loadingDatos"
                :indeterminate="loadingDatos"
                absolute
                bottom
                height="5"
                color="white"
            />

        </v-toolbar>

        <v-simple-table v-if="medActivo">
            <template v-slot:default>

                <thead>
                    <tr>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Medicamento</span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Frec.</span></th>
                        <th class="orange lighten-3 text-center" v-for="itm in horariosApp" :key="itm.id"><span class="body-2 font-weight-black">{{itm.label.split(':')[0]}}</span></th>
                        <!--
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Prox</span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        -->
                    </tr>
                </thead>

                <tbody>
                    <tr class="mb-3" v-for="item in aplicadosTodos" :key="item.id">

                        <!-- el ícono SRAM -->

                        <td width="1%" class="textoarriba tablaDiag text-center" >
                            <v-tooltip bottom color="warning">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="amber lighten-2"
                                        class="mt-3 ml-1"
                                        @click="abreNuevoSram(item)"
                                    >
                                        mdi-alert
                                    </v-icon>
                                </template>
                                <span>Reporte S.R.A.M.</span>
                            </v-tooltip>

                            <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red lighten-2"
                                        class="mt-3 ml-1"
                                    >
                                        mdi-square
                                    </v-icon>
                                </template>
                                <span>Medicamento de alto riesgo</span>
                            </v-tooltip>
                        </td>

                        <!-- Descripción de medicamento -->
                        <td class="textoarriba tablaDiag" @click="detalleMed(item)" :class="claseTerminado(item)">
                            <!-- Activo -->
                            <div>

                                <span class="red--text text-caption" v-if="item.antibiotico">
                                    <b>{{item.concepto}} {{item.especificacion}}</b><br/>
                                </span>

                                <span v-if="!item.antibiotico" class="text-caption">
                                    {{item.concepto}} {{item.especificacion}}<br/>
                                </span>

                                <span class="text-caption">{{item.dosis}} {{item.unidad}} {{item.via}}</span><br/>

                                <div>
                                    {{item.observaciones}}
                                </div>

                                <div v-if="item.activo == 0" class="red--text font-weight-black"><br/>Suspendido</div>
                                <div v-if="item.activo == 0" class="text-caption"> {{item.fechaSuspende}} por {{item.suspende}}: {{item.razonSuspension}}</div>
                            </div>

                        </td>

                        <td width="1%" class="tablaDiag text-center" :class="claseTerminado(item)">
                            <div>
                                <span class="text-caption">{{item.frecuencia}}</span><br/>

                            </div>
                        </td>


                        <td width="1%" class="tablaDiag text-center" v-for="itm in horariosApp" :key="itm.id" :class="claseTerminado(item)">
                            <v-icon small v-if="separaAplicaciones(item, itm.label.split(':')[0])" @click="procesaMed(item, itm.label)">mdi-circle-medium</v-icon>
                            <!-- <v-icon small color="blue" v-if="!separaAplicaciones(item, itm.label.split(':')[0])" @click="entregaDatosAplicacion(item.aplicados, itm.hora)">mdi-check-bold</v-icon> -->
                            <v-chip
                                :color="entregaDatosColor(item.aplicados, itm.hora)"
                                v-if="(!separaAplicaciones(item, itm.label.split(':')[0]) && !noAplicado(item.aplicados, itm.hora))"
                                @click="entregaDatosAplicacion(item.aplicados, itm.hora)"
                                x-small
                                outlined
                            >
                                {{entregaDatosAplicacion2(item.aplicados, itm.hora)}}
                            </v-chip>
                            <div
                                :style="{
                                    color:entregaDatosColor(item.aplicados, itm.hora),
                                    fontSize: '10px'}"

                                v-if="(!separaAplicaciones(item, itm.label.split(':')[0]) && noAplicado(item.aplicados, itm.hora))"
                                @click="entregaDatosAplicacion(item.aplicados, itm.hora)"
                            >
                                <span>{{entregaDatosAplicacion2(item.aplicados, itm.hora)}}<br/>¡No!</span>
                            </div>
                            <div class="text-caption" v-if="entregaDatosAplicacion3(item.aplicados, itm.hora)">{{entregaDatosAplicacion3(item.aplicados, itm.hora)}} ml</div>


                        </td>


                        <!-- Próxima aplicacion
                        <td width="1%" class="text-center" >
                            <div>
                                <span class="text-caption">{{proximaAplicacion(item)}}</span><br/>
                            </div>
                        </td>
                        -->

                        <!-- el ícono cancelar
                        <td width="1%" class="textoarriba tablaDiag" >
                            <v-icon small v-if="item.activo == 1" class="mt-3" @click="cancelaMed(item.id)">mdi-eye-off</v-icon>
                        </td>
                        -->
                    </tr>
                    <tr>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Medicamento</span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Frec.</span></th>
                        <th class="orange lighten-3 text-center" v-for="itm in horariosApp" :key="itm.id"><span class="body-2 font-weight-black">{{itm.label.split(':')[0]}}</span></th>
                        <!--
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black">Prox</span></th>
                        <th class="orange lighten-3 text-center"><span class="body-2 font-weight-black"></span></th>
                        -->
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <agrega-medicamento
            :dialogMed.sync='dialogMed'
            :estaFecha='estaFecha'
            :internamiento='internamiento'
            @nuevoGuardado='cargaMedTodos()'
        />
        <bolea-medicamento
            :entrada='boleaEsteMed'
            :estaFecha='estaFecha'
            :internamiento='internamiento'
            :dialogMed.sync='boleaDialog'
            :horaAplica.sync='horaAplica'
            @boleado='cargaMedTodos()'
        />

        <dialog-app
            :dialogApp.sync="editAdmin"
            :dt="estaAplicacion"
            @agregado="cargaMedTodos"
        />

        <v-dialog
            v-model="dialogSuspende"
            max-width="300"
            persistent
        >
            <v-card>
                <v-card-title
                    class="grey lighten-2 warning"
                >
                    Suspender medicamento
                </v-card-title>

                <v-card-text>
                    <v-text-field
                        class="pt-3"
                        v-model="suspendeText"
                        label="¿Porqué suspende?"
                        rounded
                        outlined
                        dense
                    />
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        color="warning"
                        :loading="loadingSuspende"
                        @click='suspende()'
                    >
                        Suspender
                    </v-btn>

                    <v-btn color="primary" @click="cancelaSuspende()">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <nuevo-sram
            :dialogSram.sync="dialogSram"
            :datos="esteMed"
            :internamiento='internamiento'
            :area='1'
        />

    </v-sheet>
</template>

<script>
import AgregaMedicamento from '@/components/hospital/enfe/medicamentos/AgregaMedicamento'
import BoleaMedicamento from '@/components/hospital/enfe/medicamentos/BoleaMedicamento'
import DialogApp from '@/components/hospital/enfe/medicamentos/AdminMedicamentoAplicado'
import NuevoSram from '@/components/hospital/sram/NuevoSram'
//import DialogApp from '@/components/hopsital/enfe/medicamentos/AdminMedicamentoAplicado'
export default {
    mounted(){
        this.fechaActual = this.estaFecha
        this.cargaMedTodos()
    },
    props:{
        estaFecha:String,
        internamiento:Number,
    },
    components:{
        'agrega-medicamento':AgregaMedicamento,
        'bolea-medicamento':BoleaMedicamento,
        'dialog-app':DialogApp,
        'nuevo-sram':NuevoSram,
    },
    data:()=>({
        horariosApp:[
            {id:0, label:'08:00', hora:8},{id:1, label:'10:00', hora:10},{id:2, label:'12:00', hora:12},{id:3, label:'14:00', hora:14},
            {id:4, label:'16:00', hora:16},{id:5, label:'18:00', hora:18},{id:6, label:'20:00', hora:20},{id:7, label:'22:00', hora:22},
            {id:8, label:'00:00', hora:0},{id:9, label:'02:00', hora:2},{id:10, label:'04:00', hora:4},{id:11, label:'06:00', hora:6},
        ],
        loadingDatos:false,
        muestraMed:false,
        medActivo:null,
        loadingSuspende:false,
        dialogSuspende:false,
        suspendeId:null,
        suspendeText:'',
        total:0,
        datosTable:[],
        boleaEsteMed:{},
        boleaDialog:false,
        horaAplica:'',
        // Para el modal
        dialogMed:false,

        editAdmin:false,
        estaAplicacion:{},

        dialogSram:false,

        esteMed:{},

        aplicadosTodos:[],

    }),
    methods:{
        abreNuevoSram(itm){
            //console.log(itm)
            this.esteMed = itm
            this.dialogSram = true
        },
        procesaMed(id, hora){
            if (id.mar == 1){
                this.$swal.fire({
                    icon: 'info',
                    title: 'Medicamento de alto riesgo',
                    text: 'Proceda a doble verificación antes de aplicar',
                })
            }
            this.boleaEsteMed = id
            this.horaAplica = hora
            this.boleaDialog=true
        },

        async cancelaMed(id){
            this.dialogSuspende = true
            this.suspendeId = id
            //console.log(id)
        },

        claseTerminado(itm){
            //console.log("gradiente-"+itm.turno)
            if (!itm.activo){
                return "gradiente"
            }
        },

        detalleMed(itm){
            //console.log(itm)
            this.$swal.fire({
                title: 'Detalles del medicamento',
                text: 'Registrado por '+ itm.crea +' el '+itm.dateCreated,
                confirmButtonText: 'Ok',
            })
        },
        async cargaMedTodos(){
            this.muestraMed = true
            //this.aplicadosTodos=[]
            let params = {
                internamiento:this.internamiento,
                activo:this.medActivo,
                fecha:this.estaFecha,
            }
            try{
                this.loadingDatos=true
                let med = await this.$http({
                    method:'GET',
                    url:'/enfermeria/listMedicamentosTodos',
                    params:params
                })
                //this.total = med.data.total
                this.loadingDatos=false
                this.datosTable = med.data.root
                this.aplicadosTodos = med.data.rootApp
                this.muestraMed = false
            }catch(err){
                this.loadingDatos=false
                this.muestraMed = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async suspende(){
            this.loadingSuspende = true
            //console.log(this.suspendeId)
            let params = {
                id:this.suspendeId,
                razonSuspension:this.suspendeText
            }

            try{

                let ca = await this.$http({
                    method:'POST',
                    url:'/enfermeria/suspendeMedicamento',
                    data:params
                })
                if (ca.data.estado == true){
                    this.loadingSuspende = false
                    this.dialogSuspende = false
                    this.suspendeText = ''
                    this.suspendeId = null
                    this.cargaMedTodos()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                console.log(err)
                this.loadingSuspende = false
                this.$store.dispatch('security/revisaError',err)
            }

        },

        separaAplicaciones(itm, hora){
            let ap = itm.aplicados
            //console.log(ap)
            let horas = []

            //Convierte el objeto con la hora a un array que tiene las horas redondeado a 2
            ap.forEach(ele => {
                let hh = parseInt(ele.hora.split(':')[0])
                if (hh%2 == 0){
                    // Si es par, lo agrega
                    horas.push(hh)
                } else {
                    // Si es impar, le suma uno y lo agrega
                    horas.push(hh+1)
                    if (hh == 23){
                        horas.push(0)
                    }
                }
            })

            // Si el parametro hora pasado está en el array configurado, entrega true
            let horaCalc = parseInt(hora.split(':')[0])

            if (horas.includes(horaCalc)){
                //return({tiene:false, aplicado:aplicado})
                return false

            } else {
                //return({tiene:true, aplicado:aplicado})
                return true
            }
        },

        noAplicado(ap,hora){
            let text = false
            ap.forEach(ele=>{
                let hh = parseInt(ele.hora.split(':')[0])
                if (hh == hora || hh == hora-1 || hh == 23 && hora == 0){
                    text = ele.noAplicado
                    //console.log(ele)
                }
            })
            return text
        },

        proximaAplicacion(itm){
            // Extrae los aplicados
            let ap = itm.aplicados
            // Prepara array de horas
            let horas = []
            let h1=[]
            let h2=[]
            //let horas2 = []

            if (itm.frecuencia == 'PRN' || itm.frecuencia == 'COUNT'||itm.frecuencia == 'DU'){
                // Si no es numérico
                return 'N/A'
            } else {

                // Recorre el array para buscar horas
                ap.forEach(ele => {
                    let hh = parseInt(ele.hora.split(':')[0])
                    //console.log(hh)
                    if (hh%2 == 0){
                        // Si es par, lo agrega
                        //console.log(this.moment(ele.fecha+' '+hh, 'DD-MM-YYYY HH:mm').toDate())
                        horas.push(hh)
                    } else {
                        // Si es impar, le suma uno y lo agrega
                        horas.push(hh+1)
                        //console.log(this.moment(ele.fecha+' '+hh, 'DD-MM-YYYY HH:mm').toDate())
                        if (hh == 23){
                            horas.push(0)
                        }
                    }
                })

                horas.forEach(h => {
                    //console.log(h)
                    if(h<8) {
                        h1.push(h)
                    } else {
                        h2.push(h)
                    }
                    //console.log(h1)
                    //console.log(h2)
                    //console.log(hFin)

                })

                horas = h2.concat(h1)

                if (horas[horas.length-1]){
                    // Si el array contiene datos, entrega
                    let proximaAplicacion = parseInt(itm.frecuencia)+parseInt(horas[horas.length-1])
                    if (proximaAplicacion && proximaAplicacion >=24){
                            proximaAplicacion = proximaAplicacion-24
                        }
                    return proximaAplicacion+':00'
                } else {
                    // Si el array está vacio, entrega guión
                    return '-'
                }
            }
        },
        // El modal
        entregaDatosAplicacion(ap,hora){
            let me = this
            ap.forEach(ele=>{
                let hh = parseInt(ele.hora.split(':')[0])

                if (hh == hora || hh == hora-1 || hh == 23 && hora == 0){
                    //console.log(ele.id)
                    if (me.$store.state.security.roles.includes('ROLE_ADMIN')||me.$store.state.security.roles.includes('ROLE_ENFERMERIA_ADMIN')){
                        me.estaAplicacion = ele
                        me.editAdmin = true
                    } else {
                        me.$swal.fire({
                            html: 'Registrado por '+ ele.creado +' el '+ele.dateCreated+'<br/>'+
                                'Hora: '+ele.hora+'<br/>'+ele.observaciones,
                            confirmButtonText: 'Ok',
                        })
                    }
                    //console.log(ele)
                } 
            })

        },
        entregaDatosAplicacion2(ap,hora){
            //let me = this
            let text = ''
            ap.forEach(ele=>{
                let hh = parseInt(ele.hora.split(':')[0])
                if (hh == hora || hh == hora-1 || hh == 23 && hora == 0){
                    text = ele.hora
                }
            })
            return text

        },
        // La dilución
        entregaDatosAplicacion3(ap,hora){
            //let me = this
            let text = ''
            ap.forEach(ele=>{
                let hh = parseInt(ele.hora.split(':')[0])
                if (hh == hora || hh == hora-1 || hh == 23 && hora == 0){
                    text = ele.dilu
                }
            })
            return text

        },
        entregaDatosColor(ap,hora){
            //let me = this
            let text = ''
            ap.forEach(ele=>{
                let hh = parseInt(ele.hora.split(':')[0])
                if (hh == hora || hh == hora-1 || hh == 23 && hora == 0){
                    text = ele.turno
                }
            })
            let color = 'black'
            switch (text){
                case 'mat':
                    color='blue'
                    break;
                case 'ves':
                    color='green'
                    break;
                case 'noc':
                    color='red'
                    break;
            }
            return color

        },
        cancelaSuspende(){
            this.dialogSuspende = false
        },
    },

    watch:{
        medActivo(){
            this.cargaMedTodos()
        },
        estaFecha(){
            this.cargaMedTodos()
        }
    },
}
</script>

<style>
.textoarriba{
    vertical-align: top;

}
.tablaDiag{
    border: 1px solid  #ECECEC;

    border-collapse: collapse;
}
.gradiente{
    background-image: repeating-linear-gradient( 45deg, rgba(245,127,23 ,0.2), rgba(245,127,23 ,0.2) 2px, #FFF 0, #FFF 10px);
}
.colorNa-mat{
    color:'blue';
}
.colorNa-ves{
    color:'green';
}
.colorNa-noc{
    color:'red';
}
</style>