<template>
    <v-dialog
        v-model="dialog"
        width="700"
    >
        <v-card>
            <v-form
                ref="formSram"
                v-model="formSram"
            >

                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Nuevo reporte de reacción
                </v-card-title>

                <v-card-text class="pt-5">
                    <v-row no-gutters>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-text-field
                                v-model="datosSram.medicamentoReporta"
                                :rules="[v => !!v || 'Requerido']"
                                filled
                                rounded
                                clearable
                                dense
                                label="Medicamento"
                            ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-text-field
                                v-model="datosSram.dosis"
                                :rules="[v => !!v || 'Requerido']"
                                filled
                                rounded
                                clearable
                                dense
                                label="Dosis"
                            ></v-text-field>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-text-field
                                v-model="datosSram.lote"
                                :rules="[v => !!v || 'Requerido']"
                                filled
                                rounded
                                clearable
                                dense
                                label="Lote"
                            ></v-text-field>
                        </v-col>

                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-text-field
                                v-model="datosSram.frecuencia"
                                :rules="[v => !!v || 'Requerido']"
                                filled
                                rounded
                                clearable
                                dense
                                label="Frecuencia"
                            ></v-text-field>
                        </v-col>

                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-dialog
                                v-model="dialogInicioReaccion"
                                max-width="290px"
                            >
                                <template v-slot:activator="{on, attrs}">
                                    <v-text-field
                                        v-model="datosSram.iniciaReaccion"
                                        :rules="[v => !!v || 'Requerido']"
                                        label="Inicio de reacción"
                                        v-bind="attrs"
                                        v-on="on"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        readonly
                                    />
                                </template>
                                <v-date-picker
                                    @input="dialogInicioReaccion = false"
                                    v-model="fechaPickerInicioReaccion"
                                    :max='moment(new Date()).format("YYYY-MM-DD")'
                                    locale="es-mx"
                                    scrollable
                                />

                            </v-dialog>
                        </v-col>

                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-dialog
                                v-model="dialogInicioAdministracion"
                                max-width="290px"
                            >
                                <template v-slot:activator="{on, attrs}">
                                    <v-text-field
                                        v-model="datosSram.iniciaAdministracion"
                                        label="Inicia administración"
                                        v-bind="attrs"
                                        v-on="on"

                                        filled
                                        rounded
                                        clearable
                                        dense
                                        readonly
                                    />
                                </template>
                                <v-date-picker
                                    @input="dialogInicioAdministracion = false"
                                    v-model="fechaPickerInicioAdministracion"
                                    :max='moment(new Date()).format("YYYY-MM-DD")'
                                    locale="es-mx"
                                    scrollable
                                />

                            </v-dialog>
                        </v-col>

                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-text-field
                                v-model="datosSram.motivoPrescripcion"
                                filled
                                rounded
                                clearable
                                dense
                                label="Motivo de prescripción"
                            ></v-text-field>
                        </v-col>

                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-dialog
                                v-model="dialogTerminaAdministracion"
                                max-width="290px"
                            >
                                <template v-slot:activator="{on, attrs}">
                                    <v-text-field
                                        v-model="datosSram.terminaAdministracion"
                                        label="Termina aplicación"
                                        v-bind="attrs"
                                        v-on="on"
                                        filled
                                        rounded
                                        clearable
                                        dense
                                        readonly
                                    />
                                </template>
                                <v-date-picker
                                    @input="dialogTerminaAdministracion = false"
                                    v-model="fechaPickerTerminaAdministracion"
                                    :max='moment(new Date()).format("YYYY-MM-DD")'
                                    locale="es-mx"
                                    scrollable
                                />

                            </v-dialog>
                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-textarea
                                v-model="datosSram.descripcionReaccion"
                                filled
                                rounded
                                clearable
                                dense
                                label="Descripción de la reacción"
                            ></v-textarea>

                        </v-col>
                        <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12" class="px-1">
                            <v-textarea
                                v-model="datosSram.descripcionConsecuencia"
                                filled
                                rounded
                                clearable
                                dense
                                label="Consecuencia del evento"
                            ></v-textarea>

                        </v-col>
                        
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <v-btn text color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        @click="agrega"
                        :disabled="!formSram"
                        :loading="loadingSram"
                    >
                        Agregar
                    </v-btn>

                </v-card-actions>

            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogSram:{
            type:Boolean
        },
        datos:{
            type:Object
        },
        internamiento:{
            type:Number
        },
        area:{
            type:Number
        }
    },
    data:()=>({
        formSram:false,
        loadingSram:false,
        datosSram:{},

        dialogInicioReaccion:false,
        fechaPickerInicioReaccion:'',

        dialogInicioAdministracion:false,
        fechaPickerInicioAdministracion:'',

        dialogTerminaAdministracion:false,
        fechaPickerTerminaAdministracion:'',
    }),
    methods:{
        async agrega(){
            this.loadingSram = true

            this.datosSram.internamiento = this.internamiento

            try{
                let sr = await this.$http({
                    url:'/farmacia/guardaSram',
                    method:'POST',
                    params:this.datosSram
                })

                this.loadingSram = false
                
                if (sr.data.estado === true){
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Solicitud guardada',
                        text: 'La solicitud ha sido guardada con el número de folio: '+sr.data.root.folio,
                    })
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

                //console.log(this.datosSram)
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }

            this.dialog = false
        },
        cancela(){
            this.dialog = false
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogSram
            },
            set(val){
                this.$refs.formSram.reset()
                this.$emit('update:dialogSram',val)
            },
        },
        
    },
    watch:{
        dialogSram(){
            if (this.dialogSram == true){
                //console.log('clonando objeto')
                // Internamiento
                // Paciente
                // this.datosSram={...this.datos}

                this.datosSram.medicamentoReporta = this.datos.concepto

                this.datosSram.dosis = this.datos.dosis + ' ' + this.datos.unidad+' '+this.datos.via
                this.datosSram.frecuencia = this.datos.frecuencia+' hrs. '

                this.datosSram.iniciaReaccion = this.moment(new Date()).format("DD/MM/YYYY")
                this.fechaPickerInicioReaccion = this.moment(new Date()).format("YYYY-MM-DD")

                // La fecha de inicio de administración
                let fia = this.datos.dateCreated.split(' ')[0].split('-')
                this.datosSram.iniciaAdministracion = this.moment(new Date(fia[2]+'-'+fia[1]+'-'+fia[0])).format("DD/MM/YYYY")
                this.fechaPickerInicioAdministracion = this.moment(new Date(fia[2]+'-'+fia[1]+'-'+fia[0])).format("YYYY-MM-DD")

                // La fecha de suspensión
                if(this.datos.fechaSuspende){
                    let fs = this.datos.fechaSuspende.split(' ')[0].split('-')
                    this.datosSram.terminaAdministracion = this.moment(new Date(fs[2]+'-'+fs[1]+'-'+fs[0])).format("DD/MM/YYYY")
                    this.fechaPickerTerminaAdministracion = this.moment(new Date(fs[2]+'-'+fs[1]+'-'+fs[0])).format("YYYY-MM-DD")
                }

                //this.datosSram.iniciaAdministracion = this.datos.dateCreated

                //this.datosSram.terminaAdministracion = this.datos.fechaSuspende
            }
        },
        fechaPickerInicioReaccion(val){
            let tm = val.split('-')
            this.datosSram.iniciaReaccion = tm[2]+'/'+tm[1]+'/'+tm[0]
            
        },
        fechaPickerInicioAdministracion(val){
            let tm = val.split('-')
            this.datosSram.iniciaAdministracion = tm[2]+'/'+tm[1]+'/'+tm[0]
            
        },
        fechaPickerTerminaAdministracion(val){
            let tm = val.split('-')
            this.datosSram.terminaAdministracion = tm[2]+'/'+tm[1]+'/'+tm[0]
            
        },
    },
}
</script>

<style>

</style>