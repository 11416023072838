<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-form
            ref="boleaForm"
            v-model="validaMed"
        >

            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    Aplicación de medicamentos
                    <v-spacer/>
                    <v-btn icon @click="cancela" large>
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-alert class="mt-2" dense text border="left" color="info"><span class="caption">{{fechaBol}} <b>{{turno}}</b></span></v-alert>
                    <span><b>{{entrada.concepto}}</b> {{entrada.especificacion}}</span><br/>
                    <span>
                        Dosis: {{entrada.dosis}} {{entrada.unidad}}.<br/>
                        Frecuencia: {{entrada.frecuencia}}<br/>
                        Via: {{entrada.via}}
                    </span>
                    
                    <v-form v-model="validaHora" ref="validaHora">
                        <v-text-field
                            class="mt-3"
                            ref="signoHora"
                            v-model="hora"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value)||'Formato de hora no válido',
                            ]"
                            rounded
                            clearable
                            outlined
                            label="Hora (HH:MM 24 hrs)"
                            dense
                            hint="Despues de media noche, usar día anterior"
                            
                            persistent-hint
                        ></v-text-field>
                        
                        <v-text-field
                            v-model="observaciones"
                            rounded
                            clearable
                            outlined
                            label="Observaciones"
                            dense
                        ></v-text-field>

                        <v-expand-transition>
                            <v-text-field
                                v-if="agregaBalance"
                                v-model.number="ml"
                                rounded
                                clearable
                                outlined
                                label="Cantidad (ml)"
                                dense
                                type="number"
                                :rules="[
                                    value => !!value || 'Requerido.',
                                    value => value > 0 || 'Valor MENOR al mínimo (0)',
                                ]"
                            ></v-text-field>
                        </v-expand-transition>


                    </v-form>

                    <v-checkbox
                        v-model="agregaBalance"
                        label="Agregar a balance"
                    />

                    <v-spacer/>

                </v-card-text>
                <v-card-actions>

                    

                    <v-btn
                        small
                        color="error"
                        :disabled="!validaHora"
                        :loading="loadingMed"
                        @click="noAplica"
                    >
                        <v-icon left>mdi-close</v-icon>
                        No aplicado
                    </v-btn>

                    <v-spacer/>

                    <v-btn
                        small
                        color="success"
                        @click="aplica"
                        :disabled="!validaHora"
                        :loading="loadingMed"
                    >
                        Aplicado
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                    
                </v-card-actions>
            </v-card>

        </v-form>
    </v-dialog>
</template>

<script>
export default {
    props:{
        entrada:Object,
        internamiento:Number,
        estaFecha:String,
        dialogMed:Boolean,
        horaAplica:String,
    },
    data: ()=>({
        validaMed:false,
        boleaForm:false,
        loadingMed:false,
        observaciones:'',
        hora:'',
        validaHora:false,
        agregaBalance:false,
        ml:'',
        turno:'',
        turnoCorto:'',
    }),
    methods:{
        aplica(){
            let params = {
                medicamento:this.entrada.id,
                hora: this.hora,
                fecha:this.estaFecha,
                dilu:this.ml,
                observaciones: this.observaciones,
                noAplicado:false,
            }
            this.guarda(params)
        },
        noAplica(){
            this.agregaBalance=false
            this.ml=null
            let params = {
                medicamento:this.entrada.id,
                hora: this.hora,
                fecha:this.estaFecha,
                observaciones: this.observaciones,
                dilu:null,
                noAplicado:true
            }
            this.guarda(params)
        },
        async guarda(params){
            this.loadingMes = true
            //console.log(this.entrada)
            

            try{
                let bol = await this.$http({
                    method:'POST',
                    url:'/enfermeria/boleaMedicamento',
                    data: params
                })

                
                if (bol.data.estado == true){

                    if (this.agregaBalance && this.ml > 0){

                        let prms={
                            item:3,
                            observaciones:this.entrada.concepto +' '+this.entrada.dosis+' '+this.entrada.unidad,
                            hora:this.hora,
                            cantidad: parseFloat(this.ml),
                            turno:this.turnoCorto,
                            fecha:this.estaFecha,
                            internamiento:this.internamiento,
                        }
                        try{
                            let liq = await this.$http({
                                method:'POST',
                                url: '/enfermeria/guardaInOut',
                                data: prms
                            })
    
                            this.loadingMes = false
    
                            if (liq.data.estado == true){
                                this.dialog = false
                                this.$refs.validaHora.reset()
                                this.$emit('boleado')
                            } else {
                                console.log(liq.data)
                                this.$swal.fire({
                                    icon: 'warning',
                                    title: 'No se puede guardar',
                                    text: 'No se puede agregar al balance de liquidos',
                                })
                            }
                        } catch(err){
                            this.loadingMed = false
                            console.log(err)
                            this.$store.dispatch('security/revisaError',err)
                        }
                    } else {
                        this.loadingMes = false
                        this.dialog = false
                        this.$refs.validaHora.reset()
                        this.$emit('boleado')
                    }
                } else {
                    console.log(bol.data.root)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            }catch(err){
                this.loadingMed = false
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
            }

        },
        cancela(){
            this.dialog = false
            this.$refs.validaHora.reset()
        },
        
    },
    computed:{
        dialog:{
            get(){
                return this.dialogMed
            },
            set(val){
                //console.log(val)
                this.$emit('update:dialogMed',val)
            },
        },
        fechaBol(){
            let texto = ''
            texto = this.moment(this.moment(this.estaFecha, "DD-MM-YYYY")).format("dddd MMMM DD")
            return (texto)
        }
    },
    watch:{
        dialogMed(val){
            if (val){
                //console.log(this.horaAplica)
                this.hora = this.horaAplica
            }
        },
        validaHora(){

            if (this.validaHora && this.hora){
                
                let v1 = this.hora.split(':')
                let hr = parseInt(v1[0], 10);

                if (hr < 10){
                    this.hora = '0'+hr+':'+v1[1]
                } else {
                    this.hora = v1[0]+':'+v1[1]
                }

                if (hr >=8 && hr < 15){
                    this.turno = 'Matutino'
                    this.turnoCorto='mat'
                }

                if (hr >=15 && hr < 21){
                    this.turno = 'Vespertino'
                    this.turnoCorto='ves'
                }
                if (hr >=21 && hr <= 23){
                    this.turno = 'Nocturno'
                    this.turnoCorto='noc'
                }
                if (hr >=0 && hr < 8){
                    this.turno = 'Nocturno'
                    this.turnoCorto='noc'
                }
                        
            }
        }
    },
}
</script>

<style>

</style>