<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="450"
            persistent
        >
            <v-form
                ref="medForm"
                @submit="agrega"
                onSubmit="return false;"
                v-model="validaMed"
            >
                <v-card>
                    <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                    >
                        Agregar nuevo medicamento
                    </v-card-title>

                    <v-card-text>

                        <div class="mt-3">
                            <!-- El autocomplete para los medicamentos -->
                            <v-autocomplete
                                v-model="datosMed.medicamento"
                                :items="listaMedicamentos"
                                :loading="loadingMeds"
                                label="Medicamento"
                                :search-input.sync="queryMed"
                                item-text="concepto"
                                item-value="id"
                                :rules="requerido"
                                no-filter
                                rounded
                                outlined
                                dense
                                clearable
                            >
                                <!-- El template para la lista de los datos -->
                                <template v-slot:item="data">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-tooltip bottom color="red" v-if="data.item.mar == 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        x-small
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="red lighten-2"
                                                    >
                                                        mdi-square
                                                    </v-icon>
                                                </template>
                                                <span>Medicamento de alto riesgo</span>
                                            </v-tooltip>
                                            ({{data.item.clave}}) {{data.item.concepto}}</v-list-item-title>
                                        <v-list-item-subtitle>{{data.item.especificacion}}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>


                                <!-- El template para el control de paginate -->
                                <template v-slot:append-item>
                                    <v-pagination
                                        v-model="page"
                                        circle
                                        :length="pageLength"
                                        total-visible="7"
                                    ></v-pagination>
                                </template>
                            </v-autocomplete>
                            <v-row no-gutters>
                                <v-col cols="6" class="pr-1">
                                    <v-text-field
                                        v-model="datosMed.dosis"
                                        label="Dosis"
                                        :rules="requerido"
                                        type="number"
                                        rounded
                                        outlined
                                        dense

                                    />
                                </v-col>
                                <v-col cols="6" class="pl-1">
                                    <v-text-field
                                        v-model="datosMed.unidad"
                                        label="Unidad de medida"
                                        rounded
                                        outlined
                                        :rules="requerido"
                                        dense
                                    />
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="6" class="pr-1">
                                    <v-select
                                        v-model="datosMed.frecuencia"
                                        label="Frecuencia (Hrs, PRN)"
                                        :items="listaHoras"
                                        rounded
                                        outlined
                                        :rules="requerido"
                                        dense
                                    />
                                </v-col>
                                <v-col cols="6" class="pr-1">
                                    <v-select
                                        v-model="datosMed.via"
                                        :items="listaVias"
                                        label="Via"
                                        :rules="requerido"
                                        rounded
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-text-field
                                v-model="datosMed.observaciones"
                                label="Observaciones"
                                rounded
                                outlined
                                dense
                            />
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn
                            color="success"
                            type="submit"
                            :disabled="!validaMed"
                            :loading="loadingMed"
                        >
                            Agregar
                        </v-btn>

                        <v-btn color="error" @click="cancela">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props:{
        dialogMed:Boolean,
        internamiento:Number,
        estaFecha:String,
    },
    data:()=>({
        validaMed:false,
        loadingMed:false,
        requerido: [
            v => !!v || 'Requerido',
        ],
        listaHoras:['2', '4','6', '8', '10', '12','24','PRN', 'DU', 'CONT'],
        listaVias:[
            "Oral",
            "Sublingual",
            "Inhalatoria",
            "Intravenosa",
            "Tópica",
            "Subcutanea",
            "Intramuscular",
            "Rectal",
            "Ótica",
            "Vaginal",
            "Oftálmica",
            "Intratraqueal",
            "Intrapleural",
            "Intraraquideo",
        ],

        datosMed:{
            medicamento:'',
            dosis:'',
            unidad:'',
            frecuencia:'',
            via:'',
            observaciones:'',
        },

        // Para el autocomplete
        loadingMeds:false,
        queryMed:'',
        listaMedicamentos:[],
        params:{},
        // Para la paginación
        page:1,
        total:0,

    }),

    methods:{
        // Carga medicamenentos para el AUTOCOMPLETE
        async cargaMedicamentos(/*query*/){

            if (!this.loadingMeds){
                if (this.queryMed && this.queryMed.length >= 3){

                    this.loadingMeds = true

                    this.params.tipoInsumo = 1,
                    this.params.activo = 1,
                    this.params.query = this.queryMed
                    this.params.page = this.page
                    this.params.start = (this.page -1) * 5
                    this.params.limit = 5

                    try {
                        var resp = await this.$http
                        .get('/comi/listMedicamentoCb',{
                            // Usar esto para cuando la anotacion SECURE de modelo apunta SOLO A ANÓNIMO
                            params:this.params,
                        })
                        //.then(response =>{
                        this.listaMedicamentos = resp.data.root
                        this.total = resp.data.total
                        this.loadingMeds = false

                        //console.log(me.itemsUnidades);
                        //})
                    } catch(err) {
                        console.log(err)
                        this.loadingMeds = false
                        this.$store.dispatch('security/revisaError',err)
                    }
                }
            }
        },

        async agrega(){
            this.loadingMed = true
            let params = this.datosMed
            params.internamiento = this.internamiento

            try{
                let me = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaMedicamento',
                    data:params
                })
                if (me.data.estado == true){
                    this.$refs.medForm.reset()
                    this.$emit('nuevoGuardado')
                    this.loadingMed = false
                    this.dialog = false
                    if (me.data.mar == 1){
                        this.$swal.fire({
                            icon: 'info',
                            title: 'Medicamento de alto riesgo',
                            text: 'Cada aplicación de este medicamento, se deberá proceder con doble verificación',
                        })
                    }
                } else {
                    this.loadingMed = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }catch(err){
                this.loadingMed = false
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loadingSolList = false
            }
        },
        cancela(){
            this.$refs.medForm.reset()
            this.dialog = false
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogMed
            },
            set(val){
                //console.log(val)
                this.$emit('update:dialogMed',val)
            },
        },
        // Se utiliza para el PAGE de los datos del AUTOCOMPLETE
        pageLength(){
            return (Math.ceil(this.total / 5));
        },
    },

    watch: {
        // Vigila un cambio en la unidad para iniciar la busqueda
        queryMed(){
            this.page = 1
            this.cargaMedicamentos()
            
            
        },
        page(){
            this.cargaMedicamentos()
        }
    },
}
</script>

<style>

</style>